import { track } from 'cr-static-shared-components'

export const onSocialLinkClick = ({
  tweetMsg: { content: tweetMsgContent, handle: tweetHandle },
  twitterPic,
  votePageUrl,
  votedNominee,
  categoryTitle,
  categoryTitleEn,
  social,
  icon,
}) => {
  const encodeVoteLink = encodeURIComponent(votePageUrl)
  const tweetMsg = tweetMsgContent
    .replace('[NOMINEE]', votedNominee)
    .replace('[CATEGORY]', categoryTitle)

  let twitterPicUrl = ''
  if (twitterPic !== '') twitterPicUrl = `pic.twitter.com/${twitterPic}`

  const tweetContent = encodeURIComponent(
    `${tweetMsg} ${votePageUrl} @${tweetHandle} #AnimeAwards ${twitterPicUrl}`
  )

  let url
  let windowFeatures = `resizable=0,toolbar=0,menubar=0,status=0,location=0,scrollbars=0,`

  switch (icon) {
    case 'twitter':
      url = `https://twitter.com/intent/tweet?text=${tweetContent}`
      windowFeatures += `height=380,width=660`
      break
    case 'facebook':
      url = `https://www.facebook.com/sharer/sharer.php?u=${encodeVoteLink}`
      windowFeatures += `height=436,width=626`
      break
    case 'vkontakte':
      url = `http://vk.com/share.php?url=${encodeVoteLink}`
      windowFeatures += `height=436,width=626`
      break
    default:
      break
  }

  const popup = window.open(url, `${icon}-share-dialog`, windowFeatures)

  if (window.focus) {
    popup.focus()
  }

  /* Track social share click */
  const actionDetail = {
    textOfButtonOrLink: social,
    pageOrScreen: 'AA Voting Page',
    content: `${categoryTitleEn} - ${votedNominee}`,
    position: null,
    referrer: window.location.href,
  }

  track(`AA Social Media Share Selected`, actionDetail)
}
