import winners2017 from './2017'
import winners2018 from './2018'
import winners2019 from './2019'
import winners2020 from './2020'
import winners2021 from './2021'
import winners2022 from './2022'
import categories from './categories'

export default {
  winners: {
    ...winners2022,
    ...winners2021,
    ...winners2020,
    ...winners2019,
    ...winners2018,
    ...winners2017,
  },
  ...categories,
}
