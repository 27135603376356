import React, { createContext, useState, useContext } from 'react'

import config from '@data/config'

const { nomineesList } = config

const VoteContext = createContext()
const useVoteContext = () => useContext(VoteContext)

const VoteConsumer = VoteContext.Consumer

const VoteProvider = ({ children }) => {
  const [confirmClicked, setConfirmClicked] = useState('')
  const [openCategory, setOpenCategory] = useState({})
  const [isVoteCompleted, setIsVoteCompleted] = useState(false)
  const [openSections, setOpenSections] = useState({})

  const onConfirmSubmit = (section = '', locale = '') => {
    const langFiltered = nomineesList.filter(({ langFor }) => {
      const isLangFor = langFor && langFor.find(lang => lang === locale)
      const isCatDisplayed = !langFor || isLangFor !== undefined
      return isCatDisplayed
    })
    const numCategories = langFiltered.length

    /* Check if all categories have been voted in */
    const getStoredVotes = nomineesList.map(({ key }) =>
      localStorage.getItem(`aa_category_${key}`)
    )
    const votedIn = getStoredVotes.filter(vote => vote !== null)
    const numVotedIn = votedIn.length

    if (numVotedIn === numCategories && section !== 'nextcategory') {
      setIsVoteCompleted(true)
    } else {
      setIsVoteCompleted(false)
    }
  }

  const state = {
    confirmClicked,
    openCategory,
    setOpenCategory,
    isVoteCompleted,
    onConfirmSubmit,
    setConfirmClicked,
    openSections,
    setOpenSections,
  }

  return <VoteContext.Provider value={state}>{children}</VoteContext.Provider>
}

export { VoteConsumer, VoteProvider, useVoteContext }
