import React from 'react'

import Link from '@components/link'
import { IntlContextConsumer } from '@intl'
import config from '@data/config'

const { judgesArticle, predictionTool } = config

export const renderLink = {
  link: props => {
    let url
    switch (props.href) {
      case 'pastwinners':
        url = `/pastwinners/index.html`
        break
      case 'faqs':
        url = `/faqs/index.html`
        break
      case 'watch':
        url = `/index.html#watch`
        break
      case 'categories':
        url = `/vote/index.html`
        break
      case 'vote':
        url = `/vote/index.html`
        break
      case 'contest':
        url = `https://www.rules.crunchyroll.com/`
        break
      case 'streamCR':
        url = `https://www.crunchyroll.com/`
        break
      case 'streamFB':
        url = `https://www.facebook.com/crunchyroll`
        break
      case 'streamYT':
        url = `https://www.youtube.com/user/CrunchyrollPromo`
        break
      case 'streamTTV':
        url = `https://www.twitch.tv/crunchyroll`
        break
      default:
        url = props.href
        break
    }

    const internal = /^\/(?!\/)/.test(url)
    const target = !internal ? '_blank' : ''
    const rel = !internal ? 'noreferrer noopener' : ''

    return (
      <IntlContextConsumer>
        {({ language: currentLocale }) => {
          if (props.href === 'judges') {
            if (currentLocale === 'ar') {
              return props.children
            } else {
              url = judgesArticle[currentLocale]
            }
          }

          if (props.href === 'predict') {
            url = predictionTool[currentLocale]
          }

          return (
            <Link to={url} target={target} rel={rel}>
              {props.children}
            </Link>
          )
        }}
      </IntlContextConsumer>
    )
  },
}
