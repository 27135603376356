import sitemenulinks from './sitemenulinks'
import sitedata from './sitedata'
import winners from './winners'
import nominees from './nominees'
import background from './bg'
import sbApiRequests from './sbApiRequests'

const config = {
  ...sitemenulinks,
  ...sitedata,
  ...winners,
  ...nominees,
  ...background,
  ...sbApiRequests,
}

export default config
