import React, { createContext, useContext } from 'react'

const AdContext = createContext()
const useAdContext = () => useContext(AdContext)

const AdConsumer = AdContext.Consumer

const AdProvider = ({ children }) => {
  const state = { ads: false }

  return <AdContext.Provider value={state}>{children}</AdContext.Provider>
}

export { AdConsumer, AdProvider, useAdContext }
