import React from 'react'
import { SessionProvider } from 'cr-static-shared-components'

import { AdProvider } from '@contexts/AdContext'
import { DateProvider } from '@contexts/DateContext'
import { ResetProvider } from '@contexts/ResetContext'
import { VoteProvider } from '@contexts/VoteContext'

const wrapRoot = ({ element }) => (
  <SessionProvider>
    <DateProvider>
      <ResetProvider>
        <VoteProvider>
          <AdProvider>{element}</AdProvider>
        </VoteProvider>
      </ResetProvider>
    </DateProvider>
  </SessionProvider>
)

export default wrapRoot