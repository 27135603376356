import aoty from './2022/aoty'
import best_boy from './2022/best-boy'
import best_girl from './2022/best-girl'
import best_protagonist from './2022/best-protagonist'
import best_antagonist from './2022/best-antagonist'
import best_fight_scene from './2022/best-fight-scene'
import best_director from './2022/best-director'
import best_animation from './2022/best-animation'
import best_character_design from './2022/best-character-design'
import best_score from './2022/best-score'
import best_va_jp from './2022/best-va-jp'
import best_va_en from './2022/best-va-en'
import best_opening from './2022/best-opening'
import best_ending from './2022/best-ending'
import best_action from './2022/best-action'
import best_comedy from './2022/best-comedy'
import best_drama from './2022/best-drama'
import best_romance from './2022/best-romance'
import best_fantasy from './2022/best-fantasy'
import best_film from './2022/best-film'
import best_va_ru from './2022/best-va-ru'
import best_va_de from './2022/best-va-de'
import best_va_fr from './2022/best-va-fr'
import best_va_pt from './2022/best-va-pt'
import best_va_es_la from './2022/best-va-es-la'
import best_va_es_es from './2022/best-va-es-es'

export default {
  nomineesList: [
    aoty,
    best_animation,
    best_opening,
    best_ending,
    best_boy,
    best_girl,
    best_score,
    best_director,
    best_character_design,
    best_protagonist,
    best_antagonist,
    best_fight_scene,
    best_romance,
    best_drama,
    best_action,
    best_fantasy,
    best_comedy,
    best_film,
    best_va_jp,
    best_va_en,
    best_va_es_la,
    best_va_es_es,
    best_va_fr,
    best_va_pt,
    best_va_de,
    best_va_ru,
  ],
}
