import config from '@data/config'

const { nomineesList } = config

export const findAccordionCategory = hash =>
  nomineesList.filter(item => hash.includes(item.id))

const categoryIds = nomineesList
  .reduce((acc, curr) => {
    const x = acc.find(item => item.id === curr.id)

    if (!x) {
      return acc.concat([curr])
    } else {
      return acc
    }
  }, [])
  .reduce((acc, curr) => acc.concat([curr.id]), [])

export const hasCategoryHash = hash =>
  categoryIds.find(cat => hash.includes(cat))

export const scrollToCategory = (hash, behavior) => {
  const findIndex = categoryIds.findIndex(cat => hash.includes(cat))

  if (findIndex > -1) {
    const el = document.querySelector(hash)
    if (!el) return

    const headerHeight = document.querySelector('.header-placeholder')
      .clientHeight
    const bodyTop = document.body.getBoundingClientRect().top
    const elTop = el.getBoundingClientRect().top
    const top = elTop - bodyTop - headerHeight

    if (behavior === 'smooth') {
      return window.scrollTo({
        top,
        behavior: 'smooth',
      })
    } else {
      return window.scrollTo({ top })
    }
  }

  return
}
