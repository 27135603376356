import React, { createContext, useContext } from 'react'

const DateContext = createContext()
const useDateContext = () => useContext(DateContext)

const DateConsumer = DateContext.Consumer

const DateProvider = ({ children }) => {
  const state = {
    isAnnouncement: false,
    isVoteStart: false,
    isVoteEnd: false,
    isAwardShow: false,
    isWinners: true,
  }

  return <DateContext.Provider value={state}>{children}</DateContext.Provider>
}

export { DateConsumer, DateProvider, useDateContext }
