export const scrollToEl = (hash, behavior) => {
  const hashOnly = hash.split('?')[0]
  const el = document.querySelector(hashOnly)
  if (!el) return

  const headerHeight = document.querySelector('.header-placeholder')
    .clientHeight
  const bodyTop = document.body.getBoundingClientRect().top
  const elTop = el.getBoundingClientRect().top
  const top = elTop - bodyTop - headerHeight

  if (behavior === 'smooth') {
    window.scrollTo({
      top,
      behavior: 'smooth',
    })
  } else {
    window.scrollTo(0, top)
  }
}
