import launchCircles from './announcement/circles'
import launchElements from './announcement/elements'
import voteStartCircles from './vote/circles'
import voteStartElements from './vote/elements'
import voteEndCircles from './voteend/circles'
import voteEndElements from './voteend/elements'
import winnersElements from './winners/elements'

export default {
  backgroundCircles: {
    ...launchCircles,
    ...voteStartCircles,
    ...voteEndCircles,
  },
  backgroundElements: {
    ...launchElements,
    ...voteStartElements,
    ...voteEndElements,
    ...winnersElements,
  },
  homeBg: {
    dustTop: {
      src: [
        {
          src: 'bg/home/top-bg-s.jpg',
        },
        {
          src: 'bg/home/top-bg-l.jpg',
          media: '(min-width: 64rem)',
        },
        {
          src: 'bg/home/top-bg-m.jpg',
          media: '(min-width: 48rem)',
        },
      ],
      alt: 'Small dust beam background',
    },
    dustBottom: {
      src: [
        {
          src: 'bg/home/bottom-bg-s.jpg',
        },
        {
          src: 'bg/home/bottom-bg-l.jpg',
          media: '(min-width: 64rem)',
        },
        {
          src: 'bg/home/bottom-bg-m.jpg',
          media: '(min-width: 48rem)',
        },
      ],

      alt: 'Small dust beam background',
    },
    blueRibbon: {
      src: [
        {
          src: 'bg/home/blue-ribbon-s.png',
        },
        {
          src: 'bg/home/blue-ribbon-l.png',
          media: '(min-width: 48rem)',
        },
      ],
      alt: 'Decorative blue ribbon',
    },
  },
  judgesBg: [
    {
      src: 'bg/judges/elements-sm.png',
    },
    {
      src: 'bg/judges/elements-lg.png',
      media: '(min-width: 90rem)',
    },
    {
      src: 'bg/judges/elements-md.png',
      media: '(min-width: 48rem)',
    },
  ]
}
