export const debounce = (callback = () => {}, delay = 100) => {
  let timeout

  const debounced = (...args) => {
    if (timeout) {
      clearTimeout(timeout)
    }

    timeout = setTimeout(callback, delay, ...args)
  }

  return debounced
}
