import React from 'react'
import { Link as GatsbyLink } from '@intl'

const Link = ({
  children,
  to,
  activeClassName,
  partiallyActive,
  blank,
  ...rest
}) => {
  const internal = /^\/(?!\/)/.test(to)

  if (internal) {
    return (
      <GatsbyLink
        to={to}
        activeClassName={activeClassName}
        partiallyActive={partiallyActive}
        onMouseDown={e => e.preventDefault()}
        {...rest}
      >
        {children}
      </GatsbyLink>
    )
  }

  return (
    <a
      href={to}
      target={blank ? '_blank' : ''}
      rel={blank ? 'noopener noreferrer' : ''}
      onMouseDown={e => e.preventDefault()}
      {...rest}
    >
      {children}
    </a>
  )
}

export default Link
