import React, { createContext, useState, useEffect, useContext } from 'react'

import config from '@data/config'

const {
  nomineesList,
  contestEntry: { enterClick, signInClick, modalClosed },
} = config

const categories = nomineesList.map(({ key }) => `aa_category_${key}`)
const resetKeys = [
  ...categories,
  enterClick.key,
  signInClick.key,
  modalClosed.key,
]

const ResetContext = createContext()
const useResetContext = () => useContext(ResetContext)

const ResetConsumer = ResetContext.Consumer

const ResetProvider = ({ children }) => {
  const [hasReset, setHasReset] = useState(false)

  useEffect(() => {
    const now = Date.now()

    resetKeys.map(key => {
      const storedInfo = localStorage.getItem(key)
      const parseData = JSON.parse(storedInfo)
      const timestamp = parseData ? parseData.timestamp : null

      if (timestamp && now > timestamp) {
        localStorage.removeItem(key)
        setHasReset(true)
      }

      return null
    })
  }, [hasReset])

  return (
    <ResetContext.Provider value={hasReset}>{children}</ResetContext.Provider>
  )
}

export { ResetConsumer, ResetProvider, useResetContext }
