import React from 'react'
import PropTypes from 'prop-types'
import { Link as GatsbyLink, navigate as gatsbyNavigate } from 'gatsby'

import { IntlContextConsumer } from './intl-context'
import intlOptions from '../config'

const { pathPrefix } = intlOptions

export const withPrefix = (url) => {
  const prefix = process.env.NODE_ENV === 'production' ? pathPrefix : ''
  return `${prefix}${url}`
}

const Link = ({ to, language, children, onClick, ...rest }) => (
  <IntlContextConsumer>
    {(intl) => {
      const languageLink = language || intl.language
      const link =
        intl.routed || language
          ? `/${languageLink}${pathPrefix}${to}`
          : withPrefix(to)

      const handleClick = (e) => {
        if (language) {
          localStorage.setItem('gatsby-intl-language', language)
        }
        if (onClick) {
          onClick(e)
        }
      }

      return (
        <GatsbyLink {...rest} to={link} onClick={handleClick}>
          {children}
        </GatsbyLink>
      )
    }}
  </IntlContextConsumer>
)

Link.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string,
  language: PropTypes.string,
}

Link.defaultProps = {
  to: '',
}

export default Link

export const navigate = (to, options) => {
  if (typeof window === 'undefined') {
    return
  }

  const { language, routed } = window.___gatsbyIntl
  const link = routed ? `/${language}${pathPrefix}${to}` : withPrefix(to)
  gatsbyNavigate(link, options)
}

export const changeLocale = (language, to) => {
  if (typeof window === 'undefined') {
    return
  }
  const { routed } = window.___gatsbyIntl

  const removeLocalePart = (pathname) => {
    if (!routed) {
      return pathname
    }
    const i = pathname.indexOf(`/`, 1)
    return pathname.substring(i)
  }

  const removePathPrefix = (pathname) => pathname.replace(pathPrefix, '')

  const pathname =
    to || removeLocalePart(removePathPrefix(window.location.pathname))

  const link =
    language !== 'en'
      ? `/${language}${pathPrefix}${pathname}${window.location.search}`
      : withPrefix(`${pathname}${window.location.search}`)

  localStorage.setItem('gatsby-intl-language', language)
  window.location.assign(link)
}